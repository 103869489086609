import React, { useEffect, useState } from 'react';
import type { NextPage } from 'next';
import type { AppProps } from 'next/app';
import { Router, useRouter } from 'next/router';
import NProgress from 'nprogress';
import '@/styles/mixins.less';
import '@/styles/fonts.css';
import '@/styles/globals.less';
import 'nprogress/nprogress.css';
import { App as AntdApp } from 'antd';
import ConfigProvider from '@/context/ConfigProvider';
import UserProvider from '@/context/UserProvider';
import withTheme from '@/theme';
import '../lib/hooks/common/firebaseConfig/init';
import { appWithTranslation } from 'next-i18next';
import { Locale } from 'antd/lib/locale';
import { CurrentLanguage } from '@/shared/language';

import dynamic from 'next/dynamic';
// import LoginResult from '@/components/LoginResult';
// import SelectReportsContent from '@/components/Common/SelectReportsAlertContent';
// import Login from '@/components/Login';
// import CookieConsentContent from '@/components/Common/CookieConsent';
// import PayAlertContent from '@/components/Common/PayAlertContent';

// import LoginResult from '@/components/LoginResult';
// import SelectReportsContent from '@/components/Common/SelectReportsAlertContent';

// import nextI18nConfig from '../../next-i18next.config'

import { Poppins } from 'next/font/google';

// 配置字体
const poppins = Poppins({
  subsets: ['latin'], // 指定需要的字符子集
  weight: ['400', '500', '600', '700', '800'], // 指定需要的字体权重
  display: 'swap', // 优化字体显示的加载方式
  fallback: ['system-ui', 'sans-serif'] // 设置系统字体作为回退字体
});

Router.events.on('routeChangeStart', NProgress.start);
Router.events.on('routeChangeError', NProgress.done);
Router.events.on('routeChangeComplete', NProgress.done);

require('../styles/hs-theme.less');

const CreateReportAlertContent = dynamic(
  () => import('@/components/Common/CreateReportAlertContent'),
);

const LoginResult = dynamic(
  () => import('@/components/LoginResult'),
);
const SelectReportsContent = dynamic(
  () => import('@/components/Common/SelectReportsAlertContent'),
);
const Login = dynamic(
  () => import('@/components/Login'),
);
const CookieConsentContent = dynamic(
  () => import('@/components/Common/CookieConsent'),
);

export type NextPageWithLayout<P = {}> = NextPage<P> & {
  getLayout?: (page: React.ReactElement) => React.ReactNode;
};

type AppPropsWithLayout<P = {}> = AppProps<P> & {
  Component: NextPageWithLayout<P>;
};

function App({ Component, pageProps }: AppPropsWithLayout) {
  const [loc, setLoc] = useState<Locale>();
  // Use the layout defined at the page level, if available
  const getLayout = Component.getLayout ?? ((page) => page);
  const router = useRouter();

  useEffect(() => {
    setLoc(CurrentLanguage(router.locale).locale);
  }, [router.locale]);

  return withTheme(
    <div className={poppins.className}>
      <AntdApp>
        <ConfigProvider>
          <UserProvider>
            {getLayout(<Component {...pageProps} />)}
            <Login />
            <LoginResult />
            <CreateReportAlertContent />
            <SelectReportsContent />
            {/* <PayAlertContent /> */}
            <CookieConsentContent />
          </UserProvider>
        </ConfigProvider>
      </AntdApp>
    </div>,
    loc,
  );
}

export default appWithTranslation(App);
