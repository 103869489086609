import axios from '@/shared/axios';
import {
  BabyPhotoOrderDetailModel,
  BaseBookModel,
  BookDetailModel,
  HomeArticleModel,
  HomeGPTsModel,
  HomeThemeModel,
  UserModel,
} from '@/types/home';
import { BaseResponse } from '@/types/web.interface';
import { ISitemapField } from 'next-sitemap';

export async function getThemeData(locale: string) {
  const res = await axios.get<BaseResponse<HomeThemeModel>>('/theme/get', {
    headers: {
      'Current-Language': locale,
    },
  });
  return res.data;
}

export async function getGptsListHotData(locale: string) {
  const res = await axios.get<BaseResponse<[HomeGPTsModel]>>('/gpts/list/hot', {
    headers: {
      'Current-Language': locale,
    },
  });
  return res.data;
}

export async function getGptsListData(locale: string) {
  const res = await axios.get<BaseResponse<[HomeGPTsModel]>>('/gpts/list', {
    headers: {
      'Current-Language': locale,
    },
  });
  return res.data;
}

export async function getArticleListHotData(locale: string) {
  const res = await axios.get<BaseResponse<[HomeArticleModel]>>('/article/list/hot', {
    headers: {
      'Current-Language': locale,
    },
  });
  return res.data;
}

export async function getArticleListData(params?: any, locale?: string) {
  const res = await axios.get<BaseResponse<[HomeArticleModel]>>('/article/list', {
    params,
    headers: {
      'Current-Language': locale,
    },
  });
  return res.data;
}

// article search list
export async function getArticleListSearchData(
  params: any,
  tagRequest: Boolean = false,
  locale?: string,
) {
  const res = await axios.get<BaseResponse<[HomeArticleModel]>>(
    tagRequest ? '/article/list/label' : '/article/list/search',
    {
      params,
      headers: {
        'Current-Language': locale,
      },
    },
  );
  return res.data;
}

// gpts search list
export async function getGptsListSearchData(params: any, locale?: string) {
  const res = await axios.get<BaseResponse<[HomeGPTsModel]>>('/gpts/list/search', {
    params,
    headers: {
      'Current-Language': locale,
    },
  });
  return res.data;
}

// article detail
export async function getArticleDetailData(id: string, locale?: string) {
  const res = await axios.get<BaseResponse<HomeArticleModel>>(`/article/detail/${id}`, {
    headers: {
      'Current-Language': locale,
    },
  });
  return res.data;
}

export async function getNewArticleDetailData(titleId: string, locale?: string) {
  const res = await axios.get<BaseResponse<HomeArticleModel>>(`/article/detail/title/${titleId}`, {
    headers: {
      'Current-Language': locale,
    },
  });
  return res.data;
}

// article detail preview
export async function getArticlePreviewDetailData(id: string, locale?: string) {
  const res = await axios.get<BaseResponse<HomeArticleModel>>(`/article/preview/detail/${id}`, {
    headers: {
      'Current-Language': locale,
    },
  });
  return res.data;
}

// gpts detail
export async function getGptsDetailData(id: string, locale?: string) {
  const res = await axios.get<BaseResponse<HomeGPTsModel>>(`/gpts/detail/${id}`, {
    headers: {
      'Current-Language': locale,
    },
  });
  return res.data;
}

// article recommend
export async function getArticleRecommendList(params?: any, locale?: string) {
  const res = await axios.get<BaseResponse<[HomeArticleModel]>>(`/article/list/recommend`, {
    params,
    headers: {
      'Current-Language': locale,
    },
  });
  return res.data;
}

// article recommend
export async function getArticleRecommendTags(params: any, locale?: string) {
  const res = await axios.get<BaseResponse<[HomeArticleModel]>>(`/article/list/recommend/tag`, {
    params,
    headers: {
      'Current-Language': locale,
    },
  });
  return res.data;
}

// gpts recommend
export async function getGPTsRecommendList(params?: any, locale?: string) {
  const res = await axios.get<BaseResponse<[HomeGPTsModel]>>(`/gpts/list/recommend`, {
    params,
    headers: {
      'Current-Language': locale,
    },
  });
  return res.data;
}

// category top list
export async function getCategoryTopList(params?: any, locale?: string) {
  const res = await axios.get<BaseResponse<[HomeArticleModel]>>(`/article/list/top`, {
    params,
    headers: {
      'Current-Language': locale,
    },
  });
  return res.data;
}

// category top list
export async function getSitemapList(params?: any) {
  const res = await axios.get<BaseResponse<[ISitemapField]>>(`/sitemap/list`, {
    params,
  });
  return res.data;
}

// books list
export async function getBooksListData(params: any) {
  const res = await axios.get<BaseResponse<[BaseBookModel]>>('/book/page', {
    params,
  });
  return res.data;
}

// Book detail
export async function getBookDetailData(id: any) {
  const res = await axios.get<BaseResponse<[BookDetailModel]>>(`/book/detail/${id}`);
  return res.data;
}

// books recommend
export async function getBookRecommendListData(params: any) {
  const res = await axios.get<BaseResponse<[BaseBookModel]>>('/book/recommend');
  return res.data;
}

// /article/list/top
export async function getArticleTopList(locale?: string) {
  const res = await axios.get<BaseResponse<[HomeArticleModel]>>(`/article/list/recommend`, {
    headers: {
      'Current-Language': locale,
    },
  });
  return res.data;
}

// user update
export async function userUpdate(token: string, locale: string) {
  const res = await axios.post<BaseResponse<UserModel>>(
    `/user/update`,
    {
      'firebase-token': token,
      source_domain: document.referrer,
    },
    {
      headers: {
        'Current-Language': locale,
        'Accept-Language': locale,
      },
    },
  );
  return res.data;
}

// /user/login_redirect
//
export async function loginRedirect(token: string, redirect: string, locale?: string) {
  const res = await axios.post<BaseResponse<UserModel>>(
    `/user/login_redirect`,
    {
      'firebase-token': token,
      redirect,
    },
    {
      headers: {
        'Current-Language': locale,
        'Accept-Language': locale,
      },
    },
  );
  return res.data;
}

// order cancel
export async function orderSubsCancelRequest(reasonType: any, itemId: any, locale: any) {
  const res = await axios.post<BaseResponse<any>>(
    `/ins/monitor/item/subs/cancel`,
    {
      reasonType: reasonType,
      item_id: itemId,
    },
    {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'Current-Language': locale,
        'Accept-Language': locale,
      },
    },
  );
  return res.data;
}

// order subs query

export async function getOrderListSearchData(params: any, locale: any) {
  const res = await axios.get<BaseResponse<[BabyPhotoOrderDetailModel]>>(
    '/stripe/order/list/plan_bowl',
    {
      params,
      headers: {
        'Current-Language': locale,
        'Accept-Language': locale,
      },
    },
  );
  return res.data;
}

export async function fetchPaidOrderInfoData(order_id: any, locale: any) {
  const res = await axios.get<BaseResponse<BabyPhotoOrderDetailModel>>(
    `/stripe/order/info/plan_bowl`,
    {
      params: {
        order_id,
      },
      headers: {
        'Current-Language': locale,
        'Accept-Language': locale,
      },
    },
  );
  return res.data;
}

// order cancel
export async function setReceiveEmail(receive_email: any, locale: any) {
  const res = await axios.post<BaseResponse<any>>(
    `/user/set/receive_email`,
    {
      receive_email,
    },
    {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'Current-Language': locale,
        'Accept-Language': locale,
      },
    },
  );
  return res.data;
}

export async function fetchUserInfoData() {
  const res = await axios.get<BaseResponse<UserModel>>(`/user/info`);
  return res.data;
}
// 首页top博主信息

export async function getHomeIndexUpInfoListApi(num: number, locale?: any) {
  const res = await axios.get<BaseResponse<any>>(`/hairstyle/blogger/top?num=${num}`);
  return res.data;
}

export async function getHairstyleCountData() {
  const res = await axios.get<BaseResponse<any>>(`/hairstyle/number`);
  return res.data;
}

export async function getExistEmail(email?: string) {
  const res = await axios.post<BaseResponse<any>>(`/user/verify/email?email=${email}`, {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  });
  return res.data;
}